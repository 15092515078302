<!--
 * @Author: zhongxq
 * @Date: 2023-10-19 22:47:30
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-30 14:51:40
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\index.vue
 * @Description: 人艺演出-正在演出
 *
-->
<template>
  <div
    class="performance-index"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="100"
  >
    <Breadcrumb class="breadcrumb-1400" />
    <div class="container">
      <section class="panel-left">
        <div>
          <el-carousel v-if="bannerList.length" style="height: 19.4792vw;" arrow="never">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <c-image
                :src="item.image"
                style="width: 100%; height: 100%; cursor: pointer"
                @click="handleClick(item)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 演出列表 -->
        <OperaList ref="listRef" :date="date" />
      </section>
      <aside class="panel-right">
        <SimpleCalendar @change="changeDate" />
        <QrCom />
        <TelCom />
        <CinemaCom />
      </aside>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getSysHomeConfigList } from "@/service/system";
import OperaList from "./components/OperaList.vue";
import SimpleCalendar from "./components/SimpleCalendar.vue";
import QrCom from "./components/QrCom.vue";
import TelCom from "./components/TelCom.vue";
import CinemaCom from "./components/CinemaCom.vue";

export default {
  components: { OperaList, SimpleCalendar, QrCom, TelCom, CinemaCom },
  data() {
    return {
      bannerList: [],
      date: "",
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    changeDate(date) {
      this.date = date;
    },
    handleMore() {
      this.$refs.listRef?.handleMore();
    },
    async getBanner() {
      const res = await getSysHomeConfigList({
        level1: "jumuBanner",
        isShow: 1,
      });
      this.bannerList = res.data || [];
    },
    handleClick(item) {
      item.url && window.open(item.url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__container {
  height: 19.4792vw;
}
.performance-index {
  height: calc(100vh - 4.1667vw);
  background-color: #f8f8fa;
  background-image: url("~@/assets/img/performance/performance-index-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  overflow-y: auto;
  p {
    margin: 0;
  }
  .container {
    display: flex;
    justify-content: center;
    // overflow-y: auto;
    margin: 0 auto 2.6042vw;
    width: 72.9167vw;
  }
  .panel {
    &-left {
      width: 45.8333vw;
      flex-shrink: 0;
    }
    &-right {
      width: 25vw;
      margin-left: 2.0833vw;
      flex-shrink: 0;
    }
  }
  .el-carousel__button {
    height: 0.5208vw;
    width: 0.5208vw;
    border-radius: 0.5208vw;
  }
  .list-card + .list-card {
    margin-top: 1.5625vw;
  }
}
</style>
