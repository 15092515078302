<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 11:11:39
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-25 15:09:19
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\SimpleCalendar.vue
 * @Description: 购票日历
 *
-->
<template>
  <div class="list-card calendar-card">
    <div class="calendar-title">
      <c-image :src="require('@/assets/img/performance/notice.png')" />
      <el-carousel
        v-if="startSellingList.length"
        style="height: 1.0417vw;"
        arrow="never"
        indicator-position="none"
        direction="vertical"
      >
        <el-carousel-item
          v-for="(item, index) in startSellingList"
          :key="index"
        >
          <span class="overflow-ellipsis">{{ item.name }}</span>
          <!-- <span class="overflow-ellipsis"
            >{{
              item.showTime && dayjs(item.showTime).format("MM月DD日")
            }}{{ item.repertoireName }}</span
          > -->
        </el-carousel-item>
      </el-carousel>
      <!-- <span v-if="startItem"
        >今日开售{{
          startItem.showTime && dayjs(startItem.showTime).format("MM月DD日")
        }}{{ startItem.repertoireName }}</span
      > -->
    </div>
    <div class="calendar-bar">
      <c-image
        :src="require('@/assets/img/performance/arrowright.png')"
        class="button button-month-minus"
        @click="minusMonth"
      />
      <div class="calendar-date">{{ date.year }}年{{ date.month + 1 }}月</div>
      <c-image
        :src="require('@/assets/img/performance/arrowright.png')"
        class="button button-month-plus"
        @click="plusMonth"
      />
    </div>
    <div class="calendar-content">
      <ul class="ul-week">
        <li v-for="item in week" :key="item" class="li-week">{{ item }}</li>
      </ul>
      <ul class="ul-day">
        <li
          v-for="item in days"
          :key="`${item.month}-${item.date}`"
          class="li-day"
          :isThisMonth="item.isThisMonth"
          :checked="item.time === checked"
          @click="handleClick(item)"
        >
          <span>{{ item.date }}</span>
          <!-- <span v-if="item.canbuy" class="can-buy">可购票</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { sellCalendar } from "@/service/performance";
import { getSysHomeConfigPage } from "@/service/system";

export default {
  name: "SimpleCalendar",
  data() {
    return {
      dayjs,
      date: {
        year: "",
        month: "",
        date: "",
      },
      current: {
        year: "",
        month: "",
        date: "",
        time: "",
      },
      week: ["日", "一", "二", "三", "四", "五", "六"],
      days: [],
      checked: dayjs().startOf("day").valueOf(),
      startSellingList: [],
    };
  },
  mounted() {
    this.getStartSell();
  },
  methods: {
    async getStartSell() {
      try {
        const res = await getSysHomeConfigPage({
          pageNo: 1,
          pageSize: 3,
          condition: {
            level1: "rightNotice",
            isShow: 1,
          },
        });
        this.startSellingList = res.data.data || [];
      } catch (e) {
        console.log(e);
      }
    },
    async getData(year, month) {
      try {
        const res = await sellCalendar({ year, month });
        let data = res.data || {};
        this.days.forEach((item) => {
          if (Object.keys(data).includes(String(item.date))) {
            item.canbuy = data[item.date];
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    handleClick: function (item) {
      this.checked = item.time;
      console.log(dayjs(item.time).format("YYYY-MM-DD"));
      this.$emit("change", dayjs(item.time).format("YYYY-MM-DD"));
    },
    plusMonth: function () {
      if (this.date.month == 11) {
        this.date.month = 0;
        this.plusYear(false);
      } else {
        this.date.month++;
      }
      this.createCalendar(this.date.year, this.date.month);
    },
    minusMonth: function () {
      // if (
      //   !(
      //     this.date.year > this.current.year ||
      //     this.date.month > this.current.month
      //   )
      // ) {
      //   return;
      // }
      if (this.date.month == 0) {
        this.date.month = 11;
        this.minusYear(false);
      } else {
        this.date.month--;
      }
      this.createCalendar(this.date.year, this.date.month);
    },
    plusYear: function (create) {
      if (this.date.year == 2049) {
        this.date.year = 1970;
      } else {
        this.date.year++;
      }
      if (create) {
        this.createCalendar(this.date.year, this.date.month);
      }
    },
    minusYear: function (create) {
      if (this.date.year == 1970) {
        this.date.year = 2049;
      } else {
        this.date.year--;
      }
      if (create) {
        this.createCalendar(this.date.year, this.date.month);
      }
    },
    createCalendar: function (year, month) {
      let dayOfFirstDay = new Date(year, month, 1).getDay();
      this.days = [];
      let all = 35;
      if (dayOfFirstDay >= 5) {
        all = 42;
      }
      for (let i = 0; i < all; i++) {
        let d = new Date(year, month, i - dayOfFirstDay + 1);

        let isThisMonth = d.getMonth() === month ? 2 : 1;

        let date = {
          year: d.getFullYear(),
          month: d.getMonth(),
          date: d.getDate(),
          isThisMonth: isThisMonth,
          time: dayjs(d).startOf("day").valueOf(),
          canbuy: false,
        };

        this.days.push(date);
      }
      this.getData(year, month + 1);
    },
  },
  created() {
    let d = new Date();
    this.date.year = d.getFullYear();
    this.date.month = d.getMonth();
    this.date.date = d.getDate();

    this.current.year = d.getFullYear();
    this.current.month = d.getMonth();
    this.current.date = d.getDate();
    this.current.time = dayjs().startOf("day").valueOf();
    this.createCalendar(this.current.year, this.current.month);
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-carousel__container {
  height: 1.0417vw;
}
.calendar-card {
  width: 100%;
  height: 23.125vw;
  padding: 1.1979vw 1.5625vw;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
}
.calendar-title {
  width: 100%;
  height: 1.5625vw;
  background: url("~@/assets/img/performance/timebg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8333vw;
  color: #ffffff;
  .img {
    width: 0.8333vw;
    height: 0.8333vw;
    margin-right: 0.4167vw;
  }
  ::v-deep(.el-carousel) {
    width: 70%;
  }
}
.calendar-bar {
  height: 2.9167vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-date {
  display: inline-block;
  width: 11.4583vw;
  font-size: 1.0417vw;
  font-weight: 800;
  color: #212122;
  line-height: 1.5625vw;
}

.button {
  width: 1.0417vw;
  height: 1.0417vw;
  cursor: pointer;
}

.button-month-minus {
  transform: rotate(180deg);
}

.ul-week {
  margin: 0 0 0.625vw;
  padding: 0;
  height: 1.5625vw;
  background: #f8f8fa;
  border-radius: 0.8854vw;
  display: grid;
  grid-template-rows: 1.5625vw;
  grid-template-columns: repeat(7, 3.125vw);
}

.li-week {
  display: inline-block;
  line-height: 1.5625vw;
}

.ul-day {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: repeat(6, 2.6042vw);
  grid-template-columns: repeat(7, 3.125vw);
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.li-day {
  display: inline-block;
  font-size: 0.9375vw;
  text-align: center;
  border-radius: 0.2083vw;
  color: #212122;
  box-sizing: border-box;
  padding-top: 0.5208vw;
  line-height: 0.9375vw;
  .can-buy {
    color: #f9680d;
    font-size: 0.625vw;
    display: block;
    // line-height: 14px;
    // white-space: pre-wrap;
  }
}

.li-day[isThisMonth="1"] {
  color: #909399;
}

.li-day[checked] {
  background: #f9680d;
  color: #ffffff;
  .can-buy {
    color: #ffffff;
  }
}

.li-day:hover {
  cursor: pointer;
  background: #f9680d;
  color: #ffffff;
  .can-buy {
    color: #ffffff;
  }
}
</style>
