import service from "./index";

// 剧目典藏
export const getTimeLine = (data) => {
  return service({
    url: "/open/portal/repertoire/yearTimeline",
    method: "post",
    data,
  });
};
// 剧目列表
export const getRepertoirePage = (data) => {
  return service({
    url: "/open/portal/repertoire/page",
    method: "post",
    data,
  });
};
// 剧目列表（优化）
export const getRepertoirePageBetter = (data) => {
  return service({
    url: "/open/portal/repertoire/getRep",
    method: "post",
    data,
  });
};
export const getRepertoirePage2 = (data) => {
  return service({
    url: "/open/portal/repertoire/homePage",
    method: "post",
    data,
  });
};
// 剧目详情
export const getRepertoireDetail = (id) => {
  return service({
    url: `/open/portal/repertoire/getById/${id}`,
    method: "get",
  });
};

// 购票时间
export const sellCalendar = (data) => {
  return service({
    url: "/open/portal/repertoire/sellCalendar",
    method: "post",
    data,
  });
};
// 开售信息
export const startSelling = () => {
  return service({
    url: "/open/portal/repertoire/startSelling",
    method: "get",
  });
};
// 剧目打分
export const repertoireScore = (data) => {
  return service({
    url: "/open/portal/repertoire/score",
    method: "post",
    data,
  });
};

// 演员打分
export const entertainerScore = (data) => {
  return service({
    url: "/open/portal/entertainer/score",
    method: "post",
    data,
  });
};

// 点击量+1
export const repertoireHit = (id) => {
  return service({
    url: `/open/portal/repertoire/hit/${id}`,
    method: "get",
  });
};

// 最近正在上演推荐
export const nearSelling = () => {
  return service({
    url: `/open/portal/repertoire/nearSelling`,
    method: "get",
  });
};

// 历史演变
export const sameName = (id) => {
  return service({
    url: `/open/portal/repertoire/sameName/${id}`,
    method: "get",
  });
};
