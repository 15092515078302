<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:08:47
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-22 18:43:21
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\CinemaCom.vue
 * @Description: 观剧指南
 *
-->
<template>
  <div class="info-card">
    <div class="title">
      <c-image :src="require('@/assets/img/performance/cinema@2x.png')" />
      <span>观剧指南</span>
    </div>
    <div class="body line-box">
      <div class="line" @click="handleDetail('')">
        <span>周边美食</span>
      </div>
      <div class="line" @click="handleDetail('desc')">
        <span>观众须知</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleDetail(tab) {
      const { href } = this.$router.resolve({
        path: "/culture/guide/index",
        query: {
          tab,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.info-card {
  margin-top: 1.5625vw;
  .title {
    box-sizing: border-box;
    padding: 0 1.5625vw;
    height: 3.125vw;
    background: rgba(249, 104, 13, 0.1);
    display: flex;
    align-items: center;
    font-size: 1.0417vw;
    font-weight: 500;
    color: #212122;
    .img {
      width: 1.0417vw;
      height: 1.0417vw;
      margin-right: 0.2604vw;
    }
  }
  .body {
    background-color: #ffffff;
  }
}
.line-box {
  padding: 0.6771vw 1.5625vw;
  .line {
    height: 2.9167vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8333vw;
    font-weight: 400;
    color: #212122;
    cursor: pointer;
    & + .line {
      border-top: 0.0521vw solid #f4f4f4;
    }
    &::after {
      content: "";
      width: 0.8333vw;
      height: 0.8333vw;
      background-image: url("~@/assets/img/performance/arrowright.png");
      background-size: 100%;
    }
  }
}
</style>
