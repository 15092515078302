<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 10:39:04
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-30 14:50:54
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\OperaList.vue
 * @Description: 演出列表
 *
-->
<template>
  <div>
    <div class="filter-row">
      <p>
        全部剧目(<span class="color-primary">{{ total }}</span
        >)
      </p>
      <div class="select-row">
        <div class="select-item">
          <span>剧场选择</span>
          <el-select v-model="form.theatresId" @change="handleSearch">
            <el-option label="全部剧场" value="all" />
            <el-option
              v-for="option in theatres"
              :key="option.id"
              :label="option.value"
              :value="option.id"
            />
          </el-select>
        </div>
        <div class="select-item">
          <span>演出方</span>
          <el-select v-model="form.performerId" @change="handleSearch">
            <el-option label="全部" value="all" />
            <el-option
              v-for="option in performer"
              :key="option.id"
              :label="option.value"
              :value="option.id"
            />
          </el-select>
        </div>
      </div>
    </div>
    <ul class="list">
      <template v-if="list.length > 0">
        <li v-for="item in list" :key="item.id" class="list-item">
          <div class="left">
            <c-image :src="item.image" />
            <span v-if="item.tag" class="tag">{{
              item.tag?.split(",")[0]
            }}</span>
          </div>
          <div class="right">
            <div class="info">
              <p class="line-name">{{ item.repertoireName }}</p>
              <p class="line-tag">
                <span class="tag">{{ item.performerName }}</span>
              </p>
              <p class="line-info">
                <c-image
                  :src="require('@/assets/img/performance/reloadtime@2x.png')"
                />
                <span>上演日期：{{ item.showTime }}</span>
              </p>
              <p class="line-info">
                <c-image
                  :src="require('@/assets/img/performance/bank@2x.png')"
                />
                <span>演出剧场：{{ item.theatresName }}</span>
              </p>
              <div class="line-info price-info">
                <c-image
                  :src="require('@/assets/img/performance/creditcard@2x.png')"
                />
                <div class="line-price">
                  <span>演出票价：</span>
                  <p class="price" style="margin: 0!important;">{{ item.price || "-" }}</p>
                </div>
              </div>
            </div>
            <span class="btn-buy" @click="handleBuy(item)">购票</span>
            <button class="btn-detail" @click="handleDetail(item)">
              查看详情
            </button>
          </div>
        </li>
      </template>
      <template v-else>
        <li class="list-item1">今日无剧目</li>
      </template>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getRepertoirePage,
  getRepertoirePageBetter,
} from "@/service/performance";
export default {
  props: {
    date: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        theatresId: "all",
        performerId: "all",
        status: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 20,
      },
      total: 0,
      list: [],
      timer: null,
      nowDate: dayjs().startOf("day").format("YYYY-MM-DD"),
    };
  },
  created() {
    this.$store.dispatch("getDict", ["theatres", "performer"]);
    this.getData2();
  },
  watch: {
    date() {
      this.handleSearch();
    },
  },
  computed: {
    theatres() {
      return this.$store.state.dictMap.theatres || [];
    },
    performer() {
      return this.$store.state.dictMap.performer || [];
    },
  },
  methods: {
    async getData() {
      try {
        const params = {
          ...this.form,
          performanceTime: this.nowDate,
          orderSign: 4,
          orderDirection: 0,
        };
        if (this.date) {
          params.performanceTime = this.date;
        }
        if (params.theatresId === "all") {
          delete params.theatresId;
        }
        if (params.performerId === "all") {
          delete params.performerId;
        }
        const res = await getRepertoirePage({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    async getData2() {
      try {
        const params = { ...this.form, performanceTime: this.nowDate };
        if (this.date) {
          params.performanceTime = this.date;
        }
        if (params.theatresId === "all") {
          delete params.theatresId;
        }
        if (params.performerId === "all") {
          delete params.performerId;
        }
        const res = await getRepertoirePageBetter({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 500);
    },
    handleBuy() {
      window.open(process.env.VUE_APP_TICKET_URL, "_blank");
    },
    // 查看详情
    handleDetail(item) {
      window.open(`#/performance/detail/${item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-input {
  font-size: 0.7292vw;
}
::v-deep .el-input__suffix {
  right: 0.2604vw;
}
::v-deep .el-select .el-input .el-select__caret {
  font-size: 0.7292vw;
}
::v-deep .el-input__icon {
  width: 1.3021vw;
}
.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.0833vw 0 1.5625vw;
  > p {
    margin: 0;
    font-size: 1.5625vw;
    font-weight: bold;
    color: #212122;
    line-height: 1.5625vw;
  }
  .select-row {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 0.8333vw;
    font-weight: 400;
    color: #606266;
    line-height: 1.25vw;
    ::v-deep(.el-input__inner) {
      color: #212122;
      width: 9.8958vw;
      height: 1.5625vw;
      line-height: 1.5625vw;
      padding: 0 0.5208vw;
      background: transparent;
      border-radius: 0.1042vw;
      border: 0.0521vw solid #d8dade;
      margin-left: 0.625vw;
    }
    ::v-deep(.el-input__icon) {
      line-height: 1.5625vw;
      color: #212122;
      font-weight: bold;
    }
    .select-item + .select-item {
      margin-left: 2.0833vw;
    }
  }
}
.list {
  padding: 0 0 2.6042vw;
  margin: 0;
  list-style: none;
  .list-item1 {
    width: 100%;
    min-height: 10.9375vw;
    box-sizing: border-box;
    position: relative;
    line-height: 10.9375vw;
    font-size: 1.5625vw;
    font-weight: 600;
    text-align: center;
  }
  .list-item {
    width: 100%;
    min-height: 10.9375vw;
    box-sizing: border-box;
    position: relative;
    padding: 0.5208vw;
    & + .list-item {
      // margin-top: 1.5625vw;
    }
    .left {
      position: absolute;
      left: 0;
      top: 0;
      width: 7.0833vw;
      height: 10.4167vw;
      box-shadow: 0.5208vw 0.5208vw 1.0417vw 0 rgba(0, 0, 0, 0.12);
      border-radius: 0.2083vw;
      z-index: 2;
      .img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0.2083vw;
      }
      .tag {
        position: absolute;
        left: 0.2083vw;
        top: 0.2083vw;
        height: 1.0417vw;
        background: #bf805f;
        border-radius: 0.1042vw;
        line-height: 1.0417vw;
        font-size: 0.625vw;
        color: #ffffff;
        padding: 0 0.4167vw;
      }
    }
    .right {
      // position: absolute;
      // left: 0.5208vw;
      // top: 0.5208vw;
      min-height: 10.4167vw;
      width: calc(100% - 0.5208vw);
      background-color: #ffffff;
      .info {
        padding: 1.0417vw 1.5625vw 0.5208vw 8.125vw;
        text-align: left;
        .line {
          &-name {
            font-size: 1.0417vw;
            font-weight: 800;
            color: #212122;
          }
          &-tag {
            margin: 0.625vw 0 0.8854vw 0;
            display: flex;
            .tag {
              font-size: 0.625vw;
              font-weight: 400;
              color: #212122;
              background: #f8d39f;
              border-radius: 0.1042vw;
              height: 1.0417vw;
              line-height: 1.0417vw;
              padding: 0 0.4167vw;
              margin-right: 0.625vw;
            }
          }
          &-info {
            margin-top: 0.7292vw;
            display: flex;
            align-items: center;
            font-size: 0.7292vw;
            font-weight: 400;
            color: #909399;
            .img {
              width: 0.8333vw;
              height: 0.8333vw;
              margin-right: 0.3125vw;
              flex-shrink: 0;
            }
          }
        }
        .price-info {
          align-items: flex-start;
          line-height: 1.1458vw;
          .img {
            margin-top: 0.1042vw;
          }
          span {
            flex-shrink: 0;
          }
          .line-price {
            display: flex;
            font-size: 0.7292vw;
            span {
              flex-shrink: 0;
            }
            .price {
              color: var(--primary-color);
              font-weight: bold;
              max-width: 23.9583vw;
            }
          }
        }
      }
      .btn-buy {
        position: absolute;
        right: 1.5625vw;
        top: 1.0417vw;
        color: var(--primary-color);
        cursor: pointer;
      }
      .btn-detail {
        position: absolute;
        right: 1.5625vw;
        bottom: 0.8854vw;
        background-color: var(--primary-color);
        color: #ffffff;
        width: 5.8333vw;
        height: 1.7708vw;
        font-size: 0.7292vw;
        border-radius: 0.2083vw;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}
</style>
